import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const SectionStyles = styled.div`
  margin-bottom: 40px;

  h2,
  h3 {
    text-align: center;
    margin: 40px 0;
  }

  p {
    text-align: center;
  }
`;

const TwoColumnStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
`;

export default function IndexPage({ data }) {
  return (
    <Layout>
      <SEO title="Home" />

      <Img fluid={data.chalkboardImage.childImageSharp.fluid} />

      <SectionStyles>
        <h2>Mobile Apps For Teachers</h2>
        <p>
          As a former classroom teacher, I'm making apps that I wanted to use in
          my classroom. My goal is to provide teachers with quick and simple
          tools, so they can devote more time to helping students, and less time
          with paperwork.
        </p>
      </SectionStyles>

      <hr />

      <TwoColumnStyles>
        <Img fluid={data.equationImage.childImageSharp.fluid} />

        <div>
          <SectionStyles>
            <h3>SIMPLE</h3>
            <p>
              Teaching is complicated enough. My goal is to make apps that are
              simple to use. They should be easy to get started, without having
              to go back and forth, or tap more than necessary.
            </p>
          </SectionStyles>
          <SectionStyles>
            <h3>HELPFUL</h3>
            <p>
              My hope is to help simplify tedious tasks, giving you more time to
              focus on your lessons, students, or even hopefully some time for
              yourself.
            </p>
          </SectionStyles>
          <SectionStyles>
            <h3>CLEAN</h3>
            <p>
              Simple visual design helps the interface be informative, without
              getting in the way, and without distracting you from your work.
            </p>
          </SectionStyles>
        </div>
      </TwoColumnStyles>
    </Layout>
  );
}

export const query = graphql`
  query {
    chalkboardImage: file(relativePath: { eq: "chalkboard.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    equationImage: file(relativePath: { eq: "equation.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
